/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p"
  }, _provideComponents(), props.components), {ButtonCta} = _components;
  if (!ButtonCta) _missingMdxReference("ButtonCta", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "top-3-reasons-to-get-hearing-aids-sooner-than-later",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#top-3-reasons-to-get-hearing-aids-sooner-than-later",
    "aria-label": "top 3 reasons to get hearing aids sooner than later permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Top 3 Reasons to Get Hearing Aids Sooner Than Later"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Over 4 million Americans will start wearing hearing aids this year – and that number continues to rise. Here are 3 reasons to get hearing aids when you start noticing issues with your hearing."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "1-theres-a-difference-between-hearing-vs-understanding",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#1-theres-a-difference-between-hearing-vs-understanding",
    "aria-label": "1 theres a difference between hearing vs understanding permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "1. There’s a difference between “hearing vs. understanding”"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "One of the most common things we hear from customers is, “I can hear when somebody talks to me, but I can’t understand what they are saying.” This is because hearing loss typically affects the higher frequencies, which are responsible for the consonant sounds like H, K, P, F, S, TH, CH, and SH. Without being able to hear subtle differences between consonants, words like “cat” and “hat,” “parrot” and “ferret” and “show” and “throw” can be hard to differentiate. But the good news is that hearing aids specifically amplify the frequencies we are struggling in. In other words, hearing aids give us back the clarity we are missing."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "2-better-hearing-can-increase-your-happiness",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#2-better-hearing-can-increase-your-happiness",
    "aria-label": "2 better hearing can increase your happiness permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "2. Better hearing can increase your happiness"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "People with hearing loss often report feeling excluded, embarrassed, lonely, and depressed because it’s hard to communicate – especially in social situations. But in recent surveys, 7 out of 10 people said that hearing aids improved their relationships with their loved ones. They also report having more energy because it requires less effort to understand what’s being said. And if money could buy happiness, one study reported that hearing aid wearers earned an average of $14k more than their colleagues with untreated hearing loss."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "3-untreated-hearing-loss-can-impact-your-mental-health",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#3-untreated-hearing-loss-can-impact-your-mental-health",
    "aria-label": "3 untreated hearing loss can impact your mental health permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "3. Untreated hearing loss can impact your mental health"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "According to studies by Dr. Frank Lin at Johns Hopkins, there is a clear association between hearing loss and brain health. Even mild levels of untreated hearing loss are connected to an increased risk of cognitive decline and dementia. Dr. Lin explains that hearing loss requires the brain to constantly put more energy into processing degraded sound, which leaves less energy for other tasks like memory, thinking, and balance. Fortunately, studies also show that using hearing aids reduces the risk of cognitive decline and dementia associated with hearing loss."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "get-expert-advice",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#get-expert-advice",
    "aria-label": "get expert advice permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Get expert advice"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "At hear.com, we believe everyone should hear well, so they can live well. We’ve already changed over 100,000 lives by making the process of finding the right hearing aids easy and convenient. If you haven’t spoken with one of our experts yet, click the button below."), "\n", React.createElement(ButtonCta, {
    copy: "Sign up for a 30-day trial",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
